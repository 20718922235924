import React, {useEffect, useState, useRef} from 'react';
import { Grid } from "semantic-ui-react";

interface IProps {
  companies: number;
  employeesReached: number;
  style?: Object
}

interface iCountsRefProps {
  companyCount: number; 
  employeeCount: number;
}

/**
 * automatically trigger counter when its visible
 */
export default ({ companies, employeesReached, style }:IProps) => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const countsRef = useRef<iCountsRefProps>({ companyCount: 0, employeeCount: 0 });

  const [ companyCountStr, setCompanyCountStr ] = useState("0");
  const [ employeeCountStr, setEmployeeCountStr ] = useState("0");
  let countInterval:NodeJS.Timeout;


  //trigger counter only once it reaches the component offset
  const [pageYOffset, setPageYOffset] = useState(0);
  const [counterTrigger, setCounterTrigger] = useState(false);

  useEffect(() => {
    const handleScrolling = ()=>{
      if(!counterTrigger){
        setPageYOffset(window.pageYOffset);
      }
    }

    if(window){
      window.addEventListener("scroll", handleScrolling);
    }
    return () => {
      if(window){
        window.removeEventListener("scroll", handleScrolling);
      }
    };
  });


  useEffect(() => {
    const componentOffset = sectionRef.current?.offsetTop;
    const withinView = componentOffset && (pageYOffset + (window.innerHeight * .8)) >= componentOffset; //.8 => helps to activate event when the component reaches to the bottom of the page view

    if (!counterTrigger && withinView && countsRef.current.companyCount === 0) {
      countsRef.current.companyCount = Math.ceil(companies * .7);
      countsRef.current.employeeCount = Math.ceil(employeesReached * .7);
      const companyIncrement = Math.ceil(companies / 100);
      const employeeIncrement = Math.ceil(employeesReached / 100);

      setCounterTrigger(true);
      countInterval = setInterval(function () {
        if (countsRef.current.companyCount + companyIncrement > companies) {
          countsRef.current.companyCount = companies;
          countsRef.current.employeeCount = employeesReached;

          setCompanyCountStr(countsRef.current.companyCount.toLocaleString());
          setEmployeeCountStr(countsRef.current.employeeCount.toLocaleString());
          clearInterval(countInterval);
        } else {
          countsRef.current.companyCount += companyIncrement;
          countsRef.current.employeeCount += employeeIncrement;
          setCompanyCountStr(countsRef.current.companyCount.toLocaleString());
          setEmployeeCountStr(countsRef.current.employeeCount.toLocaleString());
        }
      }, 80);
    }
  
  }, [pageYOffset, counterTrigger]);


  return(
    <div ref={sectionRef} style={Object.assign({paddingTop: "2em", paddingBottom: "2em", borderBottom: "1px solid black"}, style)} >
      <Grid columns={2} stackable style={{fontSize: "14px"}}>
        <Grid.Column textAlign="center" style={{ padding: ".75rem !important"}}>
          <Grid.Row style={{ fontSize: "32px" }}>Companies</Grid.Row>
          <Grid.Row style={{ fontSize: "48px", fontWeight: "700" }}>{companyCountStr}</ Grid.Row>
        </Grid.Column>
        <Grid.Column textAlign="center" style={{ padding: ".75rem !important"}}>
          <Grid.Row style={{ fontSize: "32px" }}>Employees Reached</ Grid.Row>
          <Grid.Row style={{ fontSize: "48px", fontWeight: "700" }}>{employeeCountStr}</ Grid.Row>
        </Grid.Column>
      </Grid>
    </div>
  )
}