import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Section from "../components/Section";
import { Grid } from "semantic-ui-react";
import { HeaderH1, LogoHiddenLabel, ResponsiveGrid, PartnerLogo } from "../helpers/styledComponents";
import CompanyCounter from "../components/CompanyCounter";
import { IContenfulData } from "../pages/index";

const HeaderContent = (
  <HeaderH1 style={{ marginBottom: ".5em" }}>
    Member Companies
  </HeaderH1>
);

interface IProps {
  data: IContenfulData
}

export default ({ data }: IProps)=>{
  const [ startCounter, setStartCounter] = useState(false);

  return (
    <Layout 
      mode="dark"
      headerContent={ HeaderContent } 
      startCounter={() => setStartCounter(true)}
      title="Members | Civic Alliance"
    >
      <Section mode="light" relaxedPadding>
        <CompanyCounter 
          companies={data.contentfulCivicAllianceStats.companies} 
          employeesReached={data.contentfulCivicAllianceStats.employeesReached}
          style={{paddingBottom: "5em" }}
        />
        <ResponsiveGrid centered negativeMargin>
          { data.allContentfulCivicAllianceCompany.nodes
              .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 0))
              .map(company => 
                <Grid.Column title={company.name} textAlign="center" verticalAlign="middle" key={company.id} style={{ padding: ".75rem" }}>
                  <LogoHiddenLabel>{company.name}</LogoHiddenLabel>
                  <PartnerLogo src={company.logo.file.url} logosize={company.logoSize} />
                </Grid.Column>
              )
          }
        </ResponsiveGrid>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query {
    contentfulCivicAllianceStats {
      companies
      employeesReached
    }
    allContentfulCivicAllianceCompany(filter: {commitments: {in: ["Member Company"]}, logo: {file: {fileName: {ne: null}}}}, sort: {fields: name}) {
      nodes {
        name
        id
        commitments
        logo {
          file {
            fileName
            url
          }
        }
        logoSize
      }
    }
  }
`;